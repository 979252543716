import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdOutlineImageSearch } from "react-icons/md";
import "./designDetails.css";
import griptape from "../../../../images/griptape.jpg";
import { useNavigate } from "react-router-dom";
import Warning from "../../../Warning/warning.js";

function Details(props) {
  const db = firebase.firestore();
  const storage = firebase.storage();

  const { id } = useParams();

  const navigate = useNavigate();

  const [currentDeck, setCurrentDeck] = useState({});
  const [uploadDate, setUploadDate] = useState("");
  const [currentDeckId, setCurrentDeckId] = useState("");

  const [warningOpen, setWarningOpen] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningContinueText, setWarningContinueText] = useState("");
  const [warningFunction, setWarningFunction] = useState();

  useEffect(() => {
    QueryDeck();
  }, []);

  const QueryDeck = async () => {
    try {
      const data = await db
        .collection("designs")
        .where(firebase.firestore.FieldPath.documentId(), "==", id)
        .get();
      data.docs.forEach((item) => {
        setCurrentDeckId(item.id);
        setCurrentDeck(item.data());
        setUploadDate(getDate(item.data().uploadDate));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDate = (timestamp) => {
    const current = timestamp.toDate();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  const setOnline = async () => {
    try {
      await db.collection("designs").doc(id).update({ status: "online" });
      QueryDeck();
    } catch (error) {
      console.log(error);
    }
  };

  const setOffline = async () => {
    try {
      await db.collection("designs").doc(id).update({ status: "offline" });
      QueryDeck();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteWarning = () => {
    setWarningOpen(true);
    setWarningContinueText("delete");
    setWarningTitle("Delete " + currentDeck.title + "?");
    setWarningMessage(
      "Are you sure you want to delete this design? This action will be irreversible and the design will be permanently removed from your account!"
    );
    setWarningFunction(() => () => deleteDeck());
  };

  const offlineWarning = () => {
    setWarningOpen(true);
    setWarningContinueText("take offline");
    setWarningTitle("Take " + currentDeck.title + " offline?");
    setWarningMessage(
      "Are you sure you want to take this design offline? The design will no longer be available for purchase. This action can be undone at any time."
    );
    setWarningFunction(() => () => setOffline);
  };

  const deleteDeck = async () => {
    try {
      await db.collection("designs").doc(id).delete();
      const fileName = currentDeck.fileName;
      await storage
        .ref()
        .child("designs/" + currentDeck.creatorUID + "/" + fileName)
        .delete();
      navigate("/dashboard/designs");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {currentDeckId ? (
        <div className="design-details">
          {warningOpen ? (
            <Warning
              title={warningTitle}
              message={warningMessage}
              continueText={warningContinueText}
              continue={warningFunction}
              cancel={() => setWarningOpen(false)}
            />
          ) : (
            ""
          )}
          <div className="design-details-back-container">
            <button onClick={() => navigate("/dashboard/designs")}>back</button>
          </div>
          <div className="design-details-half" id="desing-details-img-half">
            <img src={griptape} />
            <img src={currentDeck.imagePath} />
          </div>
          <div className="design-details-half">
            <div id="design-details-content-half">
              <h2>{currentDeck.title}</h2>
              <p className="design-details-date">upload date: {uploadDate}</p>
              <p className="design-details-sales">{currentDeck.sales} sold</p>
              <div className="designs-details-list-element-status">
                <div
                  id={
                    currentDeck.status === "review"
                      ? "yellow"
                      : currentDeck.status === "online"
                      ? "green"
                      : "red"
                  }
                ></div>
                <p>{currentDeck.status}</p>
              </div>
            </div>
            <div className="design-details-buttons">
              {currentDeck.status === "offline" ? (
                <button id="set-online" onClick={setOnline}>
                  Set online
                </button>
              ) : currentDeck.status === "online" ? (
                <button id="take-offline" onClick={offlineWarning}>
                  Take offline
                </button>
              ) : (
                ""
              )}

              <button id="delete" onClick={deleteWarning}>
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="design-details">
          <div className="designs-list-empty-container">
            <MdOutlineImageSearch className="designs-list-empty-icon" />
            <p>
              Sorry, we couldn't find the design you're looking for. Click{" "}
              <a onClick={() => navigate("../dashboard/designs")}>here</a> to go
              back.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;
