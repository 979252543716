import React from "react";
import "./sidebar.css";
import { SidebarData } from "./sidebarData";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/Logo2.svg";

function Sidebar(props) {
  let navigate = useNavigate();
  return (
    <div className="sidebar">
      <div className="sidebar-logo-container" onClick={() => navigate("/")}>
        <img src={logo} height="160" width="160" className="sidebar-logo" />
        <h3>Dashboard</h3>
      </div>
      <ul className="sidebar-list">
        {SidebarData.map((val, key) => {
          return (
            <li
              className="sidebar-row"
              id={window.location.pathname == val.link ? "sidebar-active" : ""}
              key={key}
              onClick={() => {
                navigate(val.link);
              }}
            >
              <div id="icon">{val.icon}</div>
              <div id="title">{val.title}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
