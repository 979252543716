import React from "react";
import { useNavigate } from "react-router-dom";
import "./about.css";

import template from "../../downloads/crispy-skate_design_template.zip";

function About(props) {
  let navigate = useNavigate();
  return (
    <div className="about-container">
      <div className="about-title">
        <h2>About us</h2>
        <p className="about-title-separator" />
      </div>
      <div className="about-info-container">
        <h3 className="about-subtitle">A flawed industry</h3>
        <p>
          These days, low quality skateboard decks can be bought pretty much
          anywhere for next to nothing. The result? Skaters are getting
          frustrated by having to buy a new board every few months and the big
          companies producing these cheap decks get filthy rich from it. To top
          it all off, the people who designed the graphics on these boards are
          getting completely ripped off.
        </p>
        <p>It's not difficult to spot the problem here…</p>
      </div>
      <div className="about-info-container">
        <h3 className="about-subtitle">The artists perspective</h3>
        <p>
          Every skater loves the feeling of getting a new board with an awesome
          graphic, it just makes you want to skate. What many don't realize,
          however, is that the artists creating these graphics are often getting
          paid close to nothing for their work. Companies usually only pay flat
          rates of a few bucks per design which they then go on and sell
          millions of. That doesn't sound very fair, does it?
        </p>
        <p>So how can we fix this?</p>
      </div>
      <div className="about-info-container">
        <h3 className="about-subtitle">Our mission</h3>
        <p>
          We want to bring artists and skaters closer together and create a
          community where everybody is treated fairly. This is done by removing
          the middleman and letting you offer your skateboard designs directly
          on our website for anyone to buy instead of having to sell them to a
          big company. Our job is to print your designs onto skateboards and
          distribute them to customers all around the world so that you can
          focus on what you do best. Creating amazing artwork!
        </p>
      </div>
      <div className="about-info-container">
        <h3 className="about-subtitle">Quality first</h3>
        <p>
          By now, it's probably clear to you that we take the quality of our
          products very seriously. All of our decks are made from sustainably
          harvested Canadian maple wood to ensure their durability and give them
          a great pop!
        </p>
      </div>
      <div className="about-info-container">
        <h3 className="about-subtitle">The numbers</h3>
        <p>
          Now for the boring (but very important) part. All decks are being sold
          in our shop for 69$. Artists receive a cut of 14% for each of their
          decks sold on our website. Therefore, you get 9.66$ of passive income
          every time one of your designs is sold.
        </p>
      </div>
      <button onClick={() => navigate("/dashboard/designs")} className="btn">
        get started!
      </button>
      <br></br>
      <br></br>
      <p>
        You can also download our{" "}
        <a href={template} download="crispy-skate_design_template.zip">
          design template
        </a>{" "}
        for some reference.
      </p>
    </div>
  );
}

export default About;
