import React, { useState } from "react";
import firebase from "firebase";
import "./account.css";

import Error from "../../Error/error.js";
import Warning from "../../../Warning/warning.js";

function Account(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();

  const [username, setUsername] = useState("");
  const [userID, setUserID] = useState("");

  const [payPal, setPayPal] = useState("");

  const [editUsername, setEditUsername] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editPayPal, setEditPayPal] = useState(false);

  const [loginPassword, setLoginPassword] = useState("");

  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [newPayPal, setNewPayPal] = useState("");

  const [currentAbout, setCurrentAbout] = useState("");
  const [about, setAbout] = useState("");
  const [aboutChanged, setAboutChanged] = useState(false);

  const [deleteWarning, setDeleteWarning] = useState(false);
  const [logOutWarning, setLogOutWarning] = useState(false);

  const [error, setError] = useState("");

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser != null) {
      setUserID(currentUser.uid);
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setUsername(doc.data().username);
          setCurrentAbout(doc.data().about);
          setPayPal(doc.data().paypal);
        })
        .catch((error) => console.log(error));
    }
  });

  const updateUsername = async () => {
    if (
      newUsername === "" ||
      newUsername.length <= 3 ||
      newUsername.length >= 16
    ) {
      setLoginPassword("");
      setError(
        "Please enter a valid username (usernames must be between 3 and 16 characters long)"
      );
      return;
    }

    if (loginPassword === "") {
      setLoginPassword("");
      setError("Please enter your password!");
      return;
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        loginPassword
      );
      await auth.currentUser.reauthenticateWithCredential(credential);
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({ username: newUsername });

      //Dani von morgen, mach hier weiter! Oder: nur CreatorID im Design speichern und dadurch darauf zugreifen?

      //await db.collection("designs").where("creatorUID", "==", userID).;

      reset();
    } catch (error) {
      setLoginPassword("");
      switch (error.code) {
        case "auth/wrong-password":
          setError("The password you entered is incorrect. Please try again!");
          return;
        default:
          setError("An error has occured. Please try again later!");
          return;
      }
    }
  };

  const updateEmail = async () => {
    if (newEmail === "") {
      setLoginPassword("");
      setError("Please enter your email address!");
      return;
    }

    if (loginPassword === "") {
      setLoginPassword("");
      setError("Please enter your password!");
      return;
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        loginPassword
      );
      await auth.currentUser.reauthenticateWithCredential(credential);
      await auth.currentUser.updateEmail(newEmail);
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({ email: newEmail });
      reset();
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setError("The email you entered is invalid");
          return;
        case "auth/email-already-in-use":
          setError("The email you entered is already in use");
          return;
        case "auth/wrong-password":
          setError("The password you entered is incorrect. Please try again!");
          return;
        default:
          setError("An error has occured. Please try again later");
          return;
      }
    }
  };

  const updatePassword = async () => {
    if (newPassword === "") {
      setNewPassword("");
      setRepeatNewPassword("");
      setLoginPassword("");

      setError("Please enter your new password!");
      return;
    }

    if (repeatNewPassword === "") {
      setNewPassword("");
      setRepeatNewPassword("");
      setLoginPassword("");

      setError("Please repeat your new password!");
      return;
    }

    if (loginPassword === "") {
      setNewPassword("");
      setRepeatNewPassword("");
      setLoginPassword("");

      setError("Please type in your current password!");
      return;
    }

    if (newPassword !== repeatNewPassword) {
      setNewPassword("");
      setRepeatNewPassword("");
      setLoginPassword("");

      setError("The passwords you typed in do not match. Please try again!");
      return;
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        loginPassword
      );
      await auth.currentUser.reauthenticateWithCredential(credential);
      await auth.currentUser.updatePassword(newPassword);
      reset();
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setError("The password you entered is incorrect. Please try again!");
          return;
        case "auth/invalid-password":
          setError(
            "The new password you entered is invalid. Your password must be at least six characters long"
          );
          return;
        case "auth/weak-password":
          setError(
            "The new password you entered is too weak. Your password must be at least six characters long"
          );
          return;
        default:
          setError("An error has occured. Please try again later");
          return;
      }
    }
  };

  const updatePayPal = async () => {
    try {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({ paypal: newPayPal });
      reset();
    } catch (error) {
      switch (error.code) {
        default:
          setError("An error has occured. Please try again later");
          return;
      }
    }
  };

  const deleteAccount = async () => {
    if (loginPassword === "") {
      setLoginPassword("");
      setError("Please enter your password!");
      return;
    }

    try {
      const allDecks = await db
        .collection("designs")
        .where("creatorUID", "==", auth.currentUser.uid)
        .get();

      for (const doc of allDecks.docs) {
        await doc.ref.delete();
        const imgRef = storage.refFromURL(doc.data().imagePath);
        await imgRef.delete();
      }

      await db.collection("users").doc(auth.currentUser.uid).delete();

      reset();
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setError("The password you entered is incorrect. Please try again!");
          return;
        default:
          setError("An error has occured. Please try again later");
          return;
      }
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        loginPassword
      );

      await auth.currentUser.reauthenticateWithCredential(credential);
      await auth.currentUser.delete();

      setLoginPassword("");
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setError("The password you entered is incorrect. Please try again!");
          return;
        default:
          setError("An error has occured. Please try again later");
          return;
      }
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      setError("Something went wrong... please try again later");
    }
  };

  const reset = () => {
    setEditEmail(false);
    setEditUsername(false);
    setEditPassword(false);
    setDeleteWarning(false);
    setEditPayPal(false);

    setLoginPassword("");
    setNewEmail("");
    setNewUsername("");
    setNewPassword("");
    setRepeatNewPassword("");
    setNewPayPal("");

    setError("");
  };

  const submitAbout = async () => {
    try {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({ about: about });

      setAboutChanged(false);
    } catch (error) {
      setError("Something went wrong... please try again later");
    }
  };

  return (
    <div>
      {error !== "" ? <Error message={error} /> : ""}
      {deleteWarning ? (
        <div className="warning-backdrop">
          <div className="warning-container">
            <h2>Delete your account</h2>
            <p>
              Are you sure you want to permanently delete your account? This
              action can't be undone!
            </p>
            <input
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
              }}
              className="auth-modal-input"
              placeholder="current password"
              type="password"
            ></input>
            <button id="cancel" onClick={reset}>
              cancel
            </button>
            <button id="continue" onClick={deleteAccount}>
              delete
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {editUsername ? (
        <div className="warning-backdrop">
          <div className="warning-container">
            <h2>Edit username</h2>
            <input
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
              }}
              className="auth-modal-input"
              placeholder="current password"
              type="password"
            ></input>
            <input
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="auth-modal-input"
              placeholder="new username"
            ></input>
            <button id="cancel" onClick={reset}>
              cancel
            </button>
            <button id="continue" onClick={updateUsername}>
              save changes
            </button>
          </div>
        </div>
      ) : editEmail ? (
        <div className="warning-backdrop">
          <div className="warning-container">
            <h2>Edit email</h2>
            <input
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
              }}
              className="auth-modal-input"
              placeholder="current password"
              type="password"
            ></input>
            <input
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="auth-modal-input"
              placeholder="new email"
            ></input>
            <button id="cancel" onClick={reset}>
              cancel
            </button>
            <button id="continue" onClick={updateEmail}>
              save changes
            </button>
          </div>
        </div>
      ) : editPassword ? (
        <div className="warning-backdrop">
          <div className="warning-container">
            <h2>Change password</h2>
            <input
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
              }}
              className="auth-modal-input"
              placeholder="current password"
              type="password"
            ></input>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="auth-modal-input"
              placeholder="new password"
              type="password"
            ></input>
            <input
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              className="auth-modal-input"
              placeholder="repeat new password"
              type="password"
            ></input>
            <button id="cancel" onClick={reset}>
              cancel
            </button>
            <button id="continue" onClick={updatePassword}>
              save changes
            </button>
          </div>
        </div>
      ) : editPayPal ? (
        <div className="warning-backdrop">
          <div className="warning-container">
            <h2>Edit PayPal-Address</h2>
            <input
              value={newPayPal}
              onChange={(e) => setNewPayPal(e.target.value)}
              className="auth-modal-input"
              placeholder="new paypal-address"
            ></input>
            <button id="cancel" onClick={reset}>
              cancel
            </button>
            <button id="continue" onClick={updatePayPal}>
              save
            </button>
          </div>
        </div>
      ) : logOutWarning ? (
        <Warning
          title="Sign out"
          message="Are you sure you would like to sign out?"
          cancel={() => setLogOutWarning(false)}
          continueText="sign out"
          continue={() => auth.signOut()}
        />
      ) : (
        ""
      )}
      <div className="account">
        <h2>Hey, {username}!</h2>
        <h3>Your account: </h3>
        <div className="account-info-container">
          <div className="account-info-edit-container">
            <div className="account-bar-container">
              <div>
                <p className="account-bar-heading">username </p>
                <p className="account-bar-info">{username}</p>
              </div>
              <button onClick={() => setEditUsername(true)}>Edit</button>
            </div>
            <div className="account-bar-container">
              <div>
                <p className="account-bar-heading">e-mail </p>
                <p className="account-bar-info">{auth.currentUser.email}</p>
              </div>
              <button onClick={() => setEditEmail(true)}>Edit</button>
            </div>

            <button
              className="account-edit-button"
              onClick={() => setEditPassword(true)}
            >
              Edit Password
            </button>
            <br />
            <br />
            <div className="account-bar-container">
              <div>
                <p className="account-bar-heading">paypal-address </p>
                <p className="account-bar-info">{payPal}</p>
              </div>
              <button onClick={() => setEditPayPal(true)}>Edit</button>
            </div>
            <p>
              Note: This address will be used to sent your commission.
              Commissions will be sent monthly. Click <a href="/terms">terms</a>{" "}
              to learn more about payment
            </p>
          </div>
          <div className="account-info-edit-container">
            <p className="account-bar-heading">about you </p>
            <textarea
              defaultValue={currentAbout}
              onChange={(e) => {
                setAbout(e.target.value);
                setAboutChanged(true);
              }}
              rows="8"
              maxLength="500"
              className="account-about"
              placeholder="Tell your customers about you! This will be publicly displayed in our shop."
            ></textarea>
            <button
              style={{ display: aboutChanged ? "" : "none" }}
              className="account-edit-button"
              onClick={submitAbout}
            >
              Save
            </button>
          </div>
          <div className="account-sign-out-container">
            <button id="account-delete" onClick={() => setDeleteWarning(true)}>
              Delete Account
            </button>
            <button
              id="account-sign-out"
              onClick={() => setLogOutWarning(true)}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
