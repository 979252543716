import React from "react";

function terms(props) {
  return (
    <div style={{ margin: "50px" }}>
      <p>
        <strong>
          <u>TERMS &amp; CONDITIONS</u>
        </strong>
      </p>
      <p>
        <strong>INTRODUCTION</strong>
      </p>
      <p>
        CrispySkate is a marketplace that empowers independent artists to sell
        their original art to customers from all over the world.
      </p>
      <p>
        For this creative hub to exist, it is crucial that all CrispySkate users
        respect the intellectual property rights of others. You must only upload
        content you created yourself, or have been authorized to use. Similarly,
        if you&rsquo;re a customer&mdash;or just lurking around our beautiful
        site&mdash;please respect the copyrights and trademarks of the works you
        find here.
      </p>
      <p>
        Please read our Terms &amp; Conditions (the &ldquo;Terms&rdquo;) below,
        as using this website means that you accept these Terms in full. Thank
        you for being here, and please e-mail&nbsp;
        <u>daniel@skateyourstyle.com</u>&nbsp;if you have any questions or
        concerns about anything here.
      </p>
      <p>
        <strong>LEGAL AGREEMENT</strong>
      </p>
      <p>
        All visitors (&ldquo;user&rdquo;, &ldquo;you&rdquo;, &ldquo;your&rdquo;)
        to the CrispySkate website at www.skateyourstyle.com (&ldquo;the
        website&rdquo;) are entering a binding legal agreement on the following
        Terms when using the website. This agreement is between the user and
        CrispySkate and use of this website indicates continued acceptance of
        these Terms.
      </p>
      <p>
        <strong>ELIGIBILITY</strong>
      </p>
      <p>
        Our services are available only to, and may only be used by, individuals
        who can form legally binding contracts under applicable law. Our
        services are not available to persons under 16 years of age.
      </p>
      <p>
        <strong>AMENDMENT TO THESE TERMS</strong>
      </p>
      <p>
        We may amend these Terms from time to time, and we will let you know
        about these changes either by sending you an e-mail to the e-mail
        address you have registered with CrispySkate or by displaying
        information about the changes on our home page, or both. Either way,
        your continuing use of the website will be taken to be an acceptance of
        the new Terms.
      </p>
      <p>
        <strong>OUR SERVICE</strong>
      </p>
      <p>
        CrispySkate provides a range of services (the &ldquo;CrispySkate
        service&rdquo;) which, amongst other things, enables you to publish,
        sell, discuss, and purchase art; interact with other members; and
        receive the benefits of CrispySkate's facilitation of product
        fulfilment, which include payment processing, customer services, and
        third-party product manufacturing. In addition, CrispySkate will arrange
        for the delivery of the physical product to your customer.
      </p>
      <p>
        The digital content on the website (&ldquo;your content&rdquo;) may be
        information, text, data, graphics, images, photographs, sound, video, or
        any other material posted online by users. Any content that you upload
        to your account&rsquo;s storefront is described as your
        &ldquo;art&rdquo;. Your art may be viewed by all users of the website
        once you elect to publish it. You may order a physical product based on
        your own art or you may offer your art for sale as part of the sale of a
        physical product. If you or a customer decide to place an order, then
        CrispySkate will forward your instructions to third parties who will
        manufacture and ship the physical product in the form specified by you
        or the customer (&ldquo;the product&rdquo;).
      </p>
      <p>
        <strong>MEMBERS</strong>
      </p>
      <p>
        You can become a registered member (&ldquo;member&rdquo;) of the website
        by setting up a password-protected account. You will be required to
        select a username and password when registering to become a member. You
        must become a member before placing any content on the website,
        including participating in forums or reviews. In its sole discretion,
        CrispySkate may refuse any user name that it decides is inappropriate
        and/or refuse any person from becoming a member.
      </p>
      <p>
        Any information you choose to publish in the public section of your
        profile may be viewed, distributed or linked to within the website or in
        the course of delivering the CrispySkate service. We care about your
        privacy and you can read our&nbsp;
        <a href="https://skateyourstyle.com/pages/terms-of-service">
          Privacy Policy
        </a>
        &nbsp;if you would like to know how we handle your personal information.
      </p>
      <p>
        <strong>PASSWORDS</strong>
      </p>
      <p>
        You are responsible for actions made on the website using your password,
        including any products purchased or sold and any content displayed or
        messages sent, even if these actions were not approved or contemplated
        by you. You are solely responsible for any loss caused by any use of
        your password by you, or any other person.
      </p>
      <p>
        You agree that you will not disclose your password to any other person
        and you will not keep your password where it can be copied or used by
        anyone other than you. If you suspect someone else knows your password,
        you must change it immediately.
      </p>
      <p>
        <strong>PUTTING CONTENT ON CRISPYSKATE</strong>
      </p>
      <p>
        You keep the copyright in any content you submit or upload to the
        website. In order to receive the CrispySkate services you grant
        CrispySkate a non-exclusive, royalty-free license to use and archive the
        content in accordance with or as reasonably contemplated by these Terms.
      </p>
      <p>
        When you submit or upload content on the website you represent and
        warrant that:
      </p>
      <ul>
        <li>
          you own all copyright in the content, or if you are not the owner,
          that you have permission to use the content, and that you have all of
          the rights required to display, reproduce and sell the content;
        </li>
        <li>
          the content you upload will not infringe the intellectual property
          rights or other rights of any person or entity, including copyright,
          moral rights, trade mark, patent or rights of privacy or publicity;
        </li>
        <li>
          your use of the website will comply with all applicable laws, rules,
          and regulations;
        </li>
        <li>
          the content does not contain material that defames or vilifies any
          person, people, races, religion or religious group and is not obscene,
          pornographic, indecent, harassing, threatening, harmful, invasive of
          privacy or publicity rights, abusive, inflammatory or otherwise
          objectionable;
        </li>
        <li>
          the content does not include malicious code, including but not limited
          to viruses, Trojan horses, worms, time bombs, cancelbots, or any other
          computer programming routines that may damage, interfere with,
          surreptitiously intercept, or expropriate any system, program, data,
          or personal information; and
        </li>
        <li>
          the content is not misleading and deceptive and does not offer or
          disseminate fraudulent goods, services, schemes, or promotions.
        </li>
        <li>
          CrispySkate reserves the right to review and, if in its sole
          discretion deemed necessary, remove any content from the website
          and/or cancel your account, should that content be found to breach
          your agreement with us and/or any applicable laws or otherwise. You
          agree to indemnify CrispySkate in respect of any direct or indirect
          damage caused due to your breach of one or more of these warranties.
        </li>
      </ul>
      <p>
        <strong>OFFERING YOUR ART FOR SALE ON A PHYSICAL PRODUCT</strong>
      </p>
      <p>
        Any member may offer their art for sale on a physical product on the
        website by appointing CrispySkate to facilitate the transaction on the
        Terms set out in the Services Agreement in Appendix A. By agreeing to
        these Terms you expressly agree to the Services Agreement in Appendix A,
        which will apply from the date on which you offer your first art for
        sale on a physical product and your continued use of the website will
        constitute ongoing agreement to the Terms therein as updated from time
        to time.
      </p>
      <p>
        <strong>PURCHASING A PRODUCT ON CRISPYSKATE</strong>
      </p>
      <p>
        Users can purchase products on the CrispySkate website using a valid
        credit card or PayPal.
      </p>
      <p>You do not have to be a member to purchase a product.</p>
      <p>The price you pay is fixed at the time of ordering.</p>
      <p>
        Once your order has been submitted, you may cancel it within two (2)
        hours of placing it by reaching out to our Customer Service team
        at&nbsp;
        <a href="mailto:daniel@skateyourstyle.com">daniel@skateyourstyle.com</a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        It is the customer&rsquo;s responsibility to ensure the product delivery
        address is correct. CrispySkate takes no responsibility for any product
        a customer does not receive because of errors in the delivery address
        given to us.
      </p>
      <p>
        We do not warrant, endorse, make representations about, or recommend any
        content or art offered or provided by any member.
      </p>
      <p>
        <strong>PAYING YOU AFTER YOUR PRODUCT IS SOLD</strong>
      </p>
      <p>Payment terms are explained in the Services Agreement.</p>
      <p>
        <strong>DELIVERY</strong>
      </p>
      <p>
        Delivery will be facilitated pursuant to the customer's instructions by
        postal or courier service and will be paid for by the customer at the
        price indicated at the time of purchase. CrispySkate will charge
        shipping charges to the customer which will vary depending upon the
        size, price, and location of the product.
      </p>
      <p>
        <strong>DAMAGED GOODS</strong>
      </p>
      <p>
        If a product is delivered to a customer, whether in electronic or
        printed format, that is electronically or physically damaged in some
        way, CrispySkate will happily contact the manufacturer or seller to
        issue a replacement copy of the product after receiving reasonable proof
        of that damage.
      </p>
      <p>
        If you receive a damaged product, then you must e-mail
        CrispySkate&rsquo;s Customer Service at&nbsp;
        <u>
          <a href="mailto:daniel@skateyourstyle.com">
            daniel@skateyourstyle.com
          </a>
        </u>
        &nbsp;to tell us about the nature of the damage and arrange for a new
        product to be sent to you at no cost to you.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          Please be aware that publishing to the website is creator-controlled
          and we do not screen all of the content on our website.&nbsp;
        </strong>
        It is the customer&rsquo;s responsibility to verify the quality of the
        content (including but not limited to misspelled words, grammatical
        errors, formatting, design, or overall appearance) before ordering a
        product. This damaged goods policy does not apply to content, only to
        the physical product.
      </p>
      <p>
        <strong>EXCESS INVENTORY</strong>
      </p>
      <p>
        You grant CrispySkate permission to dispose of any inventory that
        becomes excess as a result of refund, reprint, fraud, product sampling,
        or promotional activities, in any manner we see fit.
      </p>
      <p>
        <strong>REPORTING INAPPROPRIATE CONTENT TO CrispySkate</strong>
      </p>
      <p>
        CrispySkate does not manually screen content before it is displayed on
        the website so occasionally members may inadvertently or deliberately
        submit and display content that breaches these Terms.
      </p>
      <p>
        Inappropriate content includes, but is not limited to, content that
        infringes the copyright or other intellectual property rights of any
        person or company, or that defames or vilifies any person, people,
        races, religion or religious group; is obscene, pornographic, indecent,
        harassing, threatening, harmful, invasive of privacy or publicity
        rights, abusive, inflammatory or otherwise objectionable. CrispySkate
        reserves the right (but not the obligation) to remove or edit such
        content.
      </p>
      <p>
        Please help us by letting us know as soon as possible about any
        inappropriate, or potentially inappropriate, content you see on the
        website by e-mailing&nbsp;
        <u>
          <a href="mailto:daniel@skateyourstyle.com">
            daniel@skateyourstyle.com
          </a>
        </u>
        .
      </p>
      <p>
        <strong>SPECIFIC WARNINGS</strong>
      </p>
      <p>
        You must ensure that your access to this website and the CrispySkate
        service is not illegal or prohibited by laws that apply to you.
      </p>
      <p>
        You must take your own precautions to ensure that the process that you
        employ for accessing this website and the CrispySkate service does not
        expose you to the risk of viruses, malicious computer code, or other
        forms of interference which may damage own computer system. We do not
        accept responsibility for any interference or damage to any computer
        system that arises in connection with your use of this website or any
        linked website.
      </p>
      <p>
        We do not accept liability for any losses arising directly or indirectly
        from a failure to provide the CrispySkate service, corruption to or loss
        of data, errors or interruptions, any suspension or discontinuance of
        the CrispySkate service, or any transmissions by others in contravention
        of the registered members&rsquo; obligations as set out in these Terms.
      </p>
      <p>
        You acknowledge that we may not be able to confirm the identity of other
        registered members or prevent them acting under false pretenses or in a
        manner that infringes the rights of any person.
      </p>
      <p>
        <strong>INTELLECTUAL PROPERTY RIGHTS AND LICENSE</strong>
      </p>
      <p>
        By submitting listings to CrispySkate, you grant CrispySkate a
        non-exclusive, worldwide, royalty-free, sublicensable and transferable
        license to use, reproduce, distribute, prepare derivative works of, and
        display the content of such listings in connection with CrispySkate (and
        its successors' and affiliates') services and business in facilitating
        the sale of your product, including without limitation for promoting and
        redistributing part or all of the CrispySkate site (and derivative works
        thereof) in any media formats and through any media channels. You also
        hereby grant each user of the CrispySkate site a non-exclusive license
        to access your content through the site, and to use, reproduce,
        distribute, and display such content as permitted through the
        functionality of the site and under these Terms. The above licenses
        terminate within a commercially reasonable time after you remove or
        delete your listings from the CrispySkate site. The above licenses
        granted by you in user comments you submit, are perpetual and
        irrevocable.
      </p>
      <p>
        All intellectual property rights in this website and the CrispySkate
        service (including the software and systems underlying the CrispySkate
        service, and text, graphics, logos, icons, sound recordings and
        software) are owned by or licensed to us. Other than for the purposes
        of, and subject to the conditions prescribed under relevant copyright
        and trademark legislation throughout the world, and except as expressly
        authorized by these Terms, you may not in any form or by any means:
      </p>
      <ul>
        <li>
          use, adapt, reproduce, store, distribute, print, display, perform,
          publish, or create derivative works from any part of this website; or
        </li>
        <li>
          commercialize any information, products, or services obtained from any
          part of this website, without our written permission.
        </li>
      </ul>
      <p>
        If you use any of our trademarks in reference to our activities,
        products, or services, you must include a statement attributing that
        trademark to us. You must not use any of our trademarks in, or as the
        whole or part of, your own trademarks; in connection with activities,
        products, or services which are not ours; in a manner which may be
        confusing, misleading, or deceptive; or in a manner that disparages us
        or our information, products, or services (including this website).
      </p>
      <p>
        <strong>LINKED WEBSITES</strong>
      </p>
      <p>
        This website may contain links to other websites (&ldquo;linked
        websites&rdquo;). Those links are provided for convenience only and may
        not remain current or be maintained.
      </p>
      <p>
        We are not responsible for the content or privacy practices associated
        with linked websites.
      </p>
      <p>
        Our links with linked websites should not be construed as an
        endorsement, approval, or recommendation by us of the owners or
        operators of those linked websites, or of any information, graphics,
        materials, products or services referred to or contained on those linked
        websites, unless and to the extent stipulated to the contrary.
      </p>
      <p>
        <strong>DISCLAIMER</strong>
      </p>
      <p>
        We do not represent or guarantee that the CrispySkate service or this
        website, or any other website that is accessible using a hyperlink from
        this website will be free from errors or viruses. We do not represent or
        guarantee that access to the CrispySkate service or these websites will
        be uninterrupted.
      </p>
      <p>
        You acknowledge that the CrispySkate service or this website may be
        affected by outages, faults, or delays. Such outages, faults, or delays
        may be caused by factors, including technical difficulties with the
        performance or operation of our or another person&rsquo;s software,
        equipment or systems, traffic or technical difficulties with the
        Internet or infrastructure failures.
      </p>
      <p>
        We do not warrant that any members&rsquo; uploads to this website will
        be protected against loss, or misuse, or alteration by third parties. We
        do not warrant that all uploaded content will be available on our
        website. If we elect in our sole discretion to make available content on
        our website, we do not warrant that it will be available within a
        certain time frame.
      </p>
      <p>
        We do not accept responsibility for any loss or damage, however caused
        (including through negligence), that you may directly, or indirectly,
        suffer in connection with your use of this website or any linked
        website, nor do we accept any responsibility for any such loss arising
        out of your use of, or reliance on, the information contained on or
        accessed through this website.
      </p>
      <p>
        To the extent permitted by law, any condition or warranty that would
        otherwise be implied into this User Agreement is hereby excluded. Where
        legislation implies any condition or warranty, and that legislation
        prohibits us from excluding or modifying the application of, or our
        liability under, any such condition or warranty, that condition or
        warranty will be deemed included but our liability will be limited for a
        breach of that condition or warranty to one or more of the following,
        which we may offer as a gesture of goodwill:
      </p>
      <ul>
        <li>if the breach relates to goods:</li>
        <ul>
          <li>
            the replacement of the goods or the supply of equivalent goods;
          </li>
          <li>the repair of such goods;</li>
          <li>
            the payment of the cost of replacing the goods or of acquiring
            equivalent goods; or
          </li>
          <li>the payment of the cost of having the goods repaired; and</li>
        </ul>
        <li>if the breach relates to services:</li>
        <ul>
          <li>the supplying of the services again; or</li>
          <li>the payment of the cost of having the services supplied again</li>
        </ul>
      </ul>
      <p>
        This disclaimer set out in these Terms does not attempt or purport to
        exclude liability arising under statute if, and to the extent, such
        liability cannot be lawfully excluded.
      </p>
      <p>
        <strong>INDEMNITY</strong>
      </p>
      <p>
        You agree to indemnify, defend, and hold us, our officers, directors,
        employees, agents, and representatives harmless, as well as, all third
        parties printing, manufacturing and/or otherwise fulfilling the products
        you are selling via the website, their officers, directors, employees,
        agents and representatives harmless from and against any and all claims,
        damages, losses, liabilities, costs (including reasonable legal fees) or
        other expenses that arise directly or indirectly out of or from:
      </p>
      <ul>
        <li>your breach of any clause of these Terms;</li>
        <li>
          any allegation that any materials that you submit to us, or transmit
          to the website, infringe or otherwise violate the copyright,
          trademark, trade secret, or other intellectual property or other
          rights of any third party; and/or
        </li>
        <li>your activities in connection with the website.</li>
      </ul>
      <p>
        This indemnity will be applicable without regard to the negligence of
        any party, including any indemnified person.
      </p>
      <p>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        Your privacy is very important to us. Users of our website should refer
        to our&nbsp;
        <a href="https://https://skateyourstyle.com/pages/terms-of-service">
          Privacy Policy
        </a>
        &ndash;which is incorporated into these Terms by reference, for
        information about how we collect and use personal information.
      </p>
      <p>
        <strong>SECURITY OF INFORMATION</strong>
      </p>
      <p>
        No data transmission over the Internet can be guaranteed as totally
        secure. We strive to protect such information, however we do not warrant
        and cannot ensure the security of any information that you transmit to
        us. Accordingly, any information that you transmit to us is transmitted
        at your own risk.
      </p>
      <p>
        <strong>TERMINATION OF ACCESS</strong>
      </p>
      <p>
        Access to this website may be terminated at any time by us without
        notice. Our disclaimer will nevertheless survive any such termination.
      </p>
      <p>
        <strong>DISPUTE RESOLUTION</strong>
      </p>
      <p>
        You agree that any disputes arising from our Terms, or relating to your
        use of any part of the CrispySkate service, will be exclusively resolved
        under confidential binding arbitration held in San Francisco,
        California. All disputes will be resolved in accordance with the rules
        of ADR Services, Inc. and California law, without regard to conflicts of
        law principles.
      </p>
      <p>
        You and CrispySkate agree to submit to the personal and exclusive
        jurisdiction of the federal and state courts in San Francisco County for
        purposes of enforcing any arbitration award. Notwithstanding the
        foregoing, CrispySkate may seek injunctive or other equitable relief
        from a court of competent jurisdiction. You and CrispySkate agree that
        any dispute resolution proceedings will be conducted only on an
        individual basis and not in a class, consolidated or representative
        action. You and CrispySkate agree that any cause of action arising out
        of or related to the CrispySkate site (including but not limited to any
        services provided or made available therein) or this Agreement must
        commence within one (1) year after the cause of action arose; otherwise,
        such cause of action is permanently barred.
      </p>
      <p>
        If you have a dispute with one or more users or sellers, you release
        CrispySkate (and CrispySkate officers, directors, agents, subsidiaries,
        joint ventures and employees) from claims, demands, and damages (actual
        and consequential) of every kind of nature, known and unknown, arising
        out of or in any way connected with such disputes. If you are a
        California resident, you waive California Civil Code Section 1542 which
        states, "A general release does not extend to claims which the creditor
        does not know or suspect to exist in his favor at the time of executing
        the release, which if known by him must have materially affected his
        settlement with the debtor.
      </p>
      <p>
        <strong>GENERAL</strong>
      </p>
      <p>
        We accept no liability for any failure to comply with these Terms where
        such failure is due to circumstances beyond our reasonable control.
      </p>
      <p>
        If we waive any rights available to us under these Terms on one
        occasion, this does not mean that those rights will automatically be
        waived on any other occasion.
      </p>
      <p>
        If any of these Terms are held to be invalid, unenforceable or illegal
        for any reason, the remaining conditions shall nevertheless continue in
        full force.
      </p>
      <p>
        You may close your account at any time by e-mailing&nbsp;
        <u>daniel@skateyourstyle.com</u>&nbsp;and letting them know you want
        your account closed. We will then delete your account accordingly.
      </p>
      <p>
        <strong>APPENDIX A - SERVICES AGREEMENT</strong>
      </p>
      <p>
        You wish to use CrispySkate&rsquo;s services to facilitate marketing and
        sale of your art on a physical product and to arrange for the
        manufacture of the physical product ("your product") once an order has
        been made through www.skateyourstyle.com ("the website"). CrispySkate
        will provide these services on the terms set out in this Services
        Agreement. Additionally, CrispySkate will provide for delivery of such
        products to the customer.
      </p>
      <ol>
        <li>
          <strong> Services</strong>
        </li>
      </ol>
      <p>
        1.1 CrispySkate, acting as independent contractor under your
        instructions in relation to the performance of marketplace services,
        will market to and obtain orders from customers for the purchase of your
        products over the website and on instruction from you. CrispySkate will
        arrange for third parties to fulfil those orders by facilitating payment
        for and manufacture of your products (&ldquo;Services&rdquo;).
        CrispySkate will then arrange for the delivery of your products as per
        the customer&rsquo;s instructions. Whilst CrispySkate capacity is one of
        independent contractor in relation to the Services, it acts as your
        agent specifically in relation to the sales transaction between you and
        the customer who buys your product &ndash; see further clause 5.2 below.
      </p>
      <p>
        1.2 CrispySkate will provide the Services pursuant to this agreement
        until termination in accordance with its terms.
      </p>
      <p>
        1.3 You agree that CrispySkate is free to act in any capacity for any
        other person interested in promoting, marketing, and obtaining orders
        from members of the public for the purchase of their arts over the
        website, including any art that is the same as, or similar to, your
        products.
      </p>
      <ol start="2">
        <li>
          <strong> License and standing instructions</strong>
        </li>
      </ol>
      <p>
        2.1 You grant CrispySkate a non-exclusive royalty free license to use
        your intellectual property relating to your products for the purpose of
        enabling us to carry out the Services.
      </p>
      <p>
        2.2 You hereby instruct CrispySkate to facilitate the sale of your
        product which includes payment, processing, and arranging for
        manufacturing your product(s) in respect of the orders placed by the
        customers via the website, and CrispySkate will facilitate such payment,
        and manufacturing in accordance with reasonable business practices
        unless you otherwise instruct prior to the placement of that order by a
        customer.
      </p>
      <ol start="3">
        <li>
          <strong> Sale of your products</strong>
        </li>
      </ol>
      <p>
        3.1 The retail price charged to customers who purchase your product is a
        flat, marketplace-wide price made up of the manufacturing fee charged by
        the third party manufacturer, CrispySkate fee for hosting the
        marketplace and facilitating the transaction (the manufacturing fee and
        CrispySkate fee are referred to collectively, and inclusive of tax, as
        the &ldquo;base amount&rdquo;), your designer&rsquo;s margin
        (&ldquo;your margin&rdquo;), and any relevant sales tax (such as Sales
        Tax, GST, VAT, etc) that CrispySkate and/or you (as the case may be) are
        liable to account for to the appropriate tax authorities. Shipping
        charges will also be added to the retail price.
      </p>
      <p>
        3.2 The marketplace-wide retail price of a product may change at any
        time without specific notice to you, including during a sitewide sale,
        or when you are featured. The retail price will not change on an
        individual sale after a customer has submitted an order to the website.
      </p>
      <p>
        3.3 CrispySkate will send you an e-mail to the e-mail address you
        registered in your account to notify you when an order has been placed
        for your products.
      </p>
      <p>
        3.4 You agree that CrispySkate makes no representation that it will be
        able to procure an order for your products, whether at the retail price
        or at all, nor that you will obtain any benefit by entering into this
        Services Agreement.
      </p>
      <p>
        3.5 All items purchased from the website are manufactured pursuant to
        arrangements with third party suppliers under your instructions. This
        means that title and risk for loss for such items pass from you to the
        customer/purchaser without passing through us prior to the goods being
        delivered to the customer under the customer&rsquo;s instructions.
      </p>
      <ol start="4">
        <li>
          <strong> Payment terms</strong>
        </li>
      </ol>
      <p>
        4.1 You authorize CrispySkate to collect, hold, and distribute the
        retail price (&ldquo;sale proceeds&rdquo;) from customers on the terms
        set out in this Services Agreement. CrispySkate will also charge the
        customer for the shipping which will be retained by us and not affect
        the amount to be distributed to you.
      </p>
      <p>
        4.2 You authorize CrispySkate to deduct the base amount (which includes
        CrispySkate&rsquo;s margin for facilitation services, including tax
        where applicable) from the sales proceeds for your products before
        distributing your earnings (which will include tax where applicable).
      </p>
      <p>
        4.3 We will pay your earnings in accordance with the payment method you
        select on your account&mdash;currently only PayPal. Payments will be
        processed on the 15th day of the month, for sales made during the
        previous calendar month. For example, earnings for sales made during the
        month of February will be paid out on March 15th.
      </p>
      <p>
        4.4 If the 15th of the month falls on a weekend or national holiday,
        payments will be processed on the nearest business day.
      </p>
      <p>
        4.6 It is your responsibility to ensure CrispySkate has current details
        of your postal address and bank account details. CrispySkate will not be
        liable for any loss suffered by you if you provide us with incorrect
        details in relation to the payment method. If we are unable to pay you
        because you have given us incorrect details or your details are out of
        date, we will hold your earnings for up to twelve (12) months from the
        payment date. If you have not notified CrispySkate of any amendment to
        the payment method details in that time your proceeds will be forfeited
        to CrispySkate or donated to a charity of our choice.
      </p>
      <ol start="5">
        <li>
          <strong> Taxation responsibility</strong>
        </li>
      </ol>
      <p>
        5.1 Each sale of a product over the CrispySkate marketplace is completed
        between: (1) you the artist as the seller of the product; and (2) the
        customer as buyer of the product. In respect of each such transaction,
        CrispySkate is merely acting as your agent in facilitating the sale of
        your product to the customer.
      </p>
      <p>5.2 Subject to clause 5.3:</p>
      <p>
        (a) the amounts distributed to you will be deemed to be inclusive of any
        taxes and each party is responsible for their own taxes associated with
        each transaction or arising out of, as a result of, incidental to, or in
        connection with their obligations under this Services Agreement; and
      </p>
      <p>
        (b) you as seller of products over the CrispySkate marketplace, will:
      </p>
      <ol>
        <li>
          account for any transactional taxes imposed pursuant to applicable
          local tax laws or regulations;
        </li>
        <li>satisfy all related accounting or audit requirements; and</li>
        <li>
          at all times be solely responsible for reporting and remitting any tax
          liabilities arising out of the sale of your products together with any
          potential interest or penalties that any tax authority may levy as a
          result of non-compliance.
        </li>
      </ol>
      <p>
        Applicable taxes may include Sales Tax, VAT, GST and other transactional
        taxes. If you are registered for VAT in the EU, CrispySkate may, upon
        your request, provide you with a VAT invoice for any charges levied by
        us. CrispySkate recommends that you consult with your tax advisor as to
        the application of taxes for you the artist, as the seller of products
        over the marketplace.
      </p>
      <ol start="6">
        <li>
          <strong> Indemnity</strong>
        </li>
      </ol>
      <p>
        6.1 You hereby indemnify and will keep CrispySkate indemnified from and
        against all claims, debts, accounts, expenses, costs, liens, actions,
        and proceedings of any nature whatsoever, whether known or unknown by
        any person, arising from, incidental to, or by virtue of, the
        appointment, or any breach or non-performance of your obligations under
        this Services Agreement or arising out of your willful act, neglect or
        default in the performance of such obligations.
      </p>
      <p>
        This clause 6 will survive the termination of this Services Agreement.
      </p>
      <ol start="7">
        <li>
          <strong> Limitation of liability</strong>
        </li>
      </ol>
      <p>
        7.1 In no case will CrispySkate be liable for any consequential loss or
        damage suffered by you arising from this Services Agreement. To the
        extent permitted by law, all warranties and conditions implied by law
        are hereby expressly excluded.
      </p>
      <ol start="8">
        <li>
          <strong> Terminating this agreement</strong>
        </li>
      </ol>
      <p>
        8.1 You can give notice of termination of this Services Agreement by
        closing your account in the method described in the Terms &amp;
        Conditions.
      </p>
      <p>
        8.2 CrispySkate may give notice of termination of this Services
        Agreement to you in writing at any time.
      </p>
      <p>
        8.3 After notice of termination in the manner described in either clause
        8.1 or 8.2, you authorize us to complete any transactions in progress in
        relation to your products, which we will do on the terms of this
        Services Agreement. Termination of this Services Agreement will take
        effect once these transactions have been completed.
      </p>
      <p>&nbsp;</p>
    </div>
  );
}

export default terms;
