import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/home";
import Dashboard from "./components/Account/dashboard.js";
import Recovery from "./components/Account/Auth/recovery.js";

import Designs from "./components/Account/Pages/Designs/designs.js";
import Upload from "./components/Account/Pages/Upload/upload.js";
import Statistics from "./components/Account/Pages/Statistics/statistics.js";
import Payment from "./components/Account/Pages/Payment/payment.js";
import Account from "./components/Account/Pages/Account/account.js";

import DesignDetails from "./components/Account/Pages/Designs/designDetails";

import Warning from "./components/Warning/warning";

import Terms from "./components/terms.js";

function App() {
  return (
    <Router>
      <div className="main">
        {/*<Navbar auth={() => (loginModalOpen ? close() : open())} />*/}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard/*" element={<Dashboard />} />
          <Route path="/recover-password" element={<Recovery />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/dashboard/designs"
            element={<Dashboard page={<Designs />} />}
          />
          <Route
            path="/dashboard/designs/:id"
            element={<Dashboard page={<DesignDetails />} />}
          />
          <Route
            path="/dashboard/upload"
            element={<Dashboard page={<Upload />} />}
          />
          <Route
            path="/dashboard/statistics"
            element={<Dashboard page={<Statistics />} />}
          />
          <Route
            path="/dashboard/payment"
            element={<Dashboard page={<Payment />} />}
          />
          <Route
            path="/dashboard/account"
            element={<Dashboard page={<Account />} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
