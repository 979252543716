import React from "react";
import "./footer.css";
import logo from "../../images/logo.PNG";

function Footer(props) {
  return (
    <div className="footer">
      <p>
        Copyright &copy; 2022 All Rights Reserved by <a href="">Crispy Skate</a>
        .
      </p>
      <a href="./terms">Terms</a>
    </div>
  );
}

export default Footer;
