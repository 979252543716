import React from "react";
import "./warning.css";

function Warning(props) {
  return (
    <div className="warning-backdrop">
      <div className="warning-container">
        <h2>{props.title}</h2>
        <p>{props.message}</p>
        <button id="cancel" onClick={props.cancel}>
          cancel
        </button>
        <button id="continue" onClick={props.continue}>
          {props.continueText}
        </button>
      </div>
    </div>
  );
}

export default Warning;
