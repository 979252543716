import React from "react";
import "./hero.css";
import "./interactiveButton.css";
import { useNavigate } from "react-router-dom";

function Hero(props) {
  let navigate = useNavigate();

  return (
    <div className="hero-container">
      <div className="hero-half hero-header-half">
        <div className="header">
          <h1>SKATE</h1>
          <h1 id="h1-your">YOUR</h1>
          <h1>STYLE</h1>
        </div>
        <p className="description">
          You design it, we print it! Just like that your amazing artwork gets
          shared with our community of skaters from all around the world and you
          get a fair cut of the profits. It's a win-win for everyone!
        </p>
        <div className="btn-container">
          <button
            className="btn"
            onClick={() => navigate("/dashboard/designs")}
          >
            Get started
          </button>
          <button
            onClick={() => window.scrollTo({ top: 700, behavior: "smooth" })}
            className="btn"
            id="learn-more"
          >
            Learn More
          </button>
        </div>
      </div>
      <div className="hero-half hero-image-half">
        <div className="hero-image"></div>
      </div>
    </div>
  );
}

export default Hero;
