import React, { useState } from "react";
import firebase from "firebase";

function Payment(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const [balance, setBalance] = useState();
  const [withdraw, setWithdraw] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paypal, setPaypal] = useState("");
  const [valid, setValid] = useState(false);

  const [user, setUser] = useState({});

  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser != null) {
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setUser(currentUser);
          if (doc.data().balance != null) {
            console.log(doc.data().balance);
          }
        })
        .catch((error) => console.log(error));
    }
  });

  const handlePayPalChange = (e) => {
    setPaypal(e.target.value);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(e.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({ balance: 0 });
      await db.collection("payment-requests").add({
        by: user.uid,
        amount: amount,
        currentBalance: balance,
        newBalance: balance - amount,
        payPal: paypal,
      });
      setLoading(false);
      setComplete(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="account">
        <h2>Withdraw</h2>
        <div className="account-info-container">
          <div className="account-bar-container">
            <div>
              <p className="account-bar-heading">
                Current Balance (this month):{" "}
              </p>
              <p>{balance} USD</p>
            </div>
          </div>
          <div className="account-bar-container">
            <input
              type="number"
              placeholder="Enter an amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={(e) => {
                var num = parseFloat(e.target.value);
                var cleanNum = num.toFixed(2);
                setAmount(cleanNum);
              }}
            />
            <p>USD</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
