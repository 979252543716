import React, { useState } from "react";
import Sidebar from "./Sidebar/sidebar.js";
import Upload from "./Pages/Upload/upload.js";
import Auth from "./Auth/auth.js";
import firebase from "firebase/app";
import "./dashboard.css";

function Account(props) {
  const [user, setUser] = useState();

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setUser(user);
    } else {
      setUser();
    }
  });

  return (
    <div className="dashboard">
      {user ? (
        <div className="dashboard-content">
          <Sidebar />
          <div className="dashboard-page">{props.page}</div>
        </div>
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default Account;
