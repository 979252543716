import React, { useEffect } from "react";
import Cropper from "react-easy-crop";
import firebase from "firebase";
import "./upload.css";
import { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";
import griptape from "../../../../images/griptape.jpg";
import { dataURLtoFile } from "../../../../utils/dataURLtoFile.js";
import { useNavigate } from "react-router-dom";
import { Slider } from "@mui/material";
import Warning from "../../../Warning/warning.js";
import Error from "../../Error/error.js";
import { FlatTree } from "framer-motion";
import template from "../../../../downloads/crispy-skate_design_template.zip";

import { cropImage } from "../../../../utils/cropImage.js";

function Upload(props) {
  const navigate = useNavigate();

  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();

  const [cropping, setCropping] = useState(false);

  const [img, setImg] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState({ x: 0, y: 0 });

  const [croppedImage, setCroppedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [title, setTitle] = useState("");

  const [username, setUsername] = useState("");

  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const [cancelWarning, setCancelWarning] = useState(false);

  const [error, setError] = useState("");

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser != null) {
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setUsername(doc.data().username);
        })
        .catch((error) => console.log(error));
    }
  });

  useEffect(() => {
    if (img) {
      setCropping(true);
    }
  }, [img]);

  function getImageDimensionsFromDataURL(dataURL) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = dataURL;
    });
  }

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImg(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onComplete = async () => {
    await cropImage(img, croppedArea, dataUriToImg);
  };

  const dataUriToImg = (dataUri) => {
    setCropping(false);
    setCroppedImage(dataUri);
  };

  useEffect(() => {
    const dimensions = getImageDimensionsFromDataURL(croppedImage).then(
      (dimensions) => {
        setImageDimensions(dimensions);
        console.log(dimensions);
      }
    );
  }, [croppedImage]);

  const onUpload = async () => {
    setError("");
    if (title.length < 3 || title.length > 16) {
      setError(
        "The title you entered is invalid. Titles must be between 3 and 16 characters long."
      );
      console.log(error);
      return;
    }

    try {
      if (imageDimensions.width < 1890 || imageDimensions.height < 7140) {
        setError(
          "Sorry! But the uploaded image size does not meet the pixel size requirements. Make sure the uploaded image has a resolution of is at least 1890px * 7140px Note: Using the provided crop tool may change the resolution of the image. "
        );
        return;
      }

      setLoading(true);

      const fileName = uuidv4() + ".jpg";
      const file = await dataURLtoFile(croppedImage, "file.jpg");

      const ref = await storage
        .ref()
        .child("designs/" + auth.currentUser.uid + "/" + fileName);

      await ref.put(file);

      const fileUrl = await ref.getDownloadURL();

      await db.collection("designs").add({
        title: title,
        creator: username,
        creatorUID: auth.currentUser.uid,
        imagePath: fileUrl,
        fileName: fileName,
        status: "review",
        valid: false,
        sales: 0,
        uploadDate: firebase.firestore.Timestamp.fromDate(new Date()),
      });
      setLoading(false);
      setComplete(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError("Something went wrong, please try agin later.");
    }
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const reset = () => {
    setComplete(false);
    setCroppedImage(null);
    setImg(null);
    setTitle("");
    setCancelWarning(false);
    setError("");
    setZoom(1);
  };

  return (
    <div className="upload">
      {error !== "" ? <Error message={error} /> : ""}
      {cancelWarning ? (
        <Warning
          title="Delete?"
          message="Are you sure you want to discard your masterpiece?"
          continueText="delete"
          cancel={() => setCancelWarning(false)}
          continue={() => reset()}
        />
      ) : (
        ""
      )}
      {cropping ? (
        <div className="upload-cropper">
          <div className="upload-cropping-container">
            <Cropper
              image={img}
              crop={crop}
              zoom={zoom}
              aspect={9 / 34}
              onZoomChange={setZoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
            />
          </div>
          <Slider
            size="small"
            min={1}
            max={3}
            step={0.001}
            defaultValue={1}
            value={zoom}
            onChange={(e, zoom) => setZoom(zoom)}
          />
          <button className="upload-cropper-done" onClick={onComplete}>
            Done
          </button>
          <p>
            Note: scroll or use the slider to adjust the zoom level and drag to
            move the image.
          </p>
          <p>
            Position the image so that everything that should be printed is
            within the white frame.
          </p>
        </div>
      ) : !croppedImage ? (
        <div>
          <h2 className="upload-info-header">Upload a deck design</h2>
          <div className="upload-start-info">
            <div id="upload-info-half" className="upload-start-info-half">
              <h4>1. Select an image</h4>
              <p>
                Please make sure your image has a high resolution to ensure the
                best results when printed.
              </p>
              <p>
                Note: Designs must not contain any offensive language or
                visuals.
              </p>
              <h4>2. Edit your design</h4>
              <p>
                Once you have selected an image, you will be able to make some
                final adjustments to your design.
              </p>
              <p>
                You will also be able to crop your design into the right format
                to be printed on a skateboard.
              </p>
              <h4>3. Done!</h4>
              <p>
                After you give your deck a title, it will be quickly reviewed
                and uploaded to our shop from where it will be sold to skaters
                all around the world!
              </p>
              <br></br>
              <br></br>
              <p>
                You can also download our{" "}
                <a href={template} download="crispy-skate_design_template.zip">
                  design template
                </a>{" "}
                for some reference.
              </p>
            </div>
            <div className="upload-start-info-half">
              <div className="upload-form">
                <input
                  className="upload-img-input"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e)}
                />
                <MdOutlineFileUpload className="upload-icon" />
                <p>Drag your image here or click to select one</p>
              </div>
            </div>
          </div>
        </div>
      ) : !loading && !complete ? (
        <div>
          <h2 className="upload-info-header">Upload your Design</h2>
          <div className="upload-info-container">
            <div className="upload-info-container-half">
              <div className="upload-image-container">
                <img src={griptape} className="upload-image" alt="" />
                <img src={croppedImage} className="upload-image" alt="" />
              </div>
              <a>
                Cropped image dimensions:{" "}
                <a
                  style={
                    imageDimensions.width >= 1890 &&
                    imageDimensions.height >= 7140
                      ? { color: "green" }
                      : { color: "red" }
                  }
                >
                  {imageDimensions.width}px * {imageDimensions.height}px
                </a>
              </a>
              <div className="upload-image-button-container">
                <button
                  onClick={() => {
                    setCropping(true);
                    setError("");
                  }}
                  className="upload-image-button"
                  id="upload-image-button-edit"
                >
                  Edit
                </button>
              </div>
            </div>
            <div id="info-half" className="upload-info-container-half">
              <p>
                Here you can edit your deck and give it an awesome title. Once
                you're happy, press the upload button and your design will be
                reviewed!
              </p>
              <label className="auth-modal-lable">title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="upload-button-container"></div>
              <button
                id="upload-image-button-done"
                className="upload-image-button"
                onClick={onUpload}
              >
                Upload
              </button>
              <button
                onClick={() => {
                  setCancelWarning(true);
                  setError("");
                }}
                className="upload-image-button"
                id="upload-image-button-delete"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : loading ? (
        <p className="upload-loading">Loading...</p>
      ) : complete ? (
        <div className="upload-complete-container">
          <BsCheck2Circle className="upload-complete-icon" />
          <p>
            Done! Your design has been successfully uploaded and is now under
            review. You will be notified once it goes online. This shouldn't
            take longer than 24 hours.
          </p>
          <div className="upload-complete-button-container">
            <button onClick={reset}>Upload new</button>
            <button
              id="upload-complete-designs"
              onClick={() => navigate("/dashboard/designs")}
            >
              Your designs
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Upload;
