import { useState, useEffect } from "react";

import firebase from "firebase";
import firebaseApp from "../../../firebaseConfig";

import Error from "../Error/error";
import { useNavigate } from "react-router-dom";

const auth = firebase.auth(firebaseApp);

function Recovery(props) {
  let navigate = useNavigate();

  const [resetEmail, setResetEmail] = useState("");

  const [counter, setCounter] = useState(0);
  const [changingEmail, setChangingEmail] = useState(true);

  const [authError, setAuthError] = useState("");

  useEffect(() => {
    setAuthError("");
  }, [changingEmail]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  const resetPassword = async () => {
    setAuthError("");
    if (resetEmail == "") {
      setAuthError("Please enter your email address to reset your password!");
      return;
    }
    try {
      await auth.sendPasswordResetEmail(resetEmail);
      setCounter(60);
      setChangingEmail(false);
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          setAuthError(
            "The email address you entered is invalid. Please type in a valid email address"
          );
          return;
        case "auth/user-not-found":
          setAuthError(
            "There is no user registered to this email address. Please check your email address or try again later"
          );
          return;
        default:
          setAuthError(
            "Something went wrong... please check your internet connection and try again later"
          );
          return;
      }
    }
  };

  return (
    <div className="auth-recover-layout">
      {authError != "" ? <Error message={authError} /> : ""}
      <div className="design-details-back-container">
        <button onClick={() => navigate("/dashboard")}>back</button>
      </div>
      <h2>Reset your password</h2>
      {changingEmail ? (
        <div className="auth-recover-container">
          <p>Please enter your email address to reset your password</p>
          <label className="auth-modal-lable">email</label>
          <input
            className="auth-modal-input"
            type="email"
            value={resetEmail}
            onChange={(event) => setResetEmail(event.target.value)}
          />
          <button onClick={resetPassword}>Send email</button>
        </div>
      ) : (
        <div className="auth-recover-container">
          <p>
            We have sent an email with the steps to recover your password to
          </p>
          <p id="auth-recover-email">{resetEmail}</p>
          <p>
            If you haven't received an email, please also check your spam folder
            or change the email address!
          </p>
          <div className="auth-recover-button-container">
            <button onClick={() => setChangingEmail(true)}>Change email</button>
            {counter <= 0 ? (
              <button onClick={resetPassword}>Send again</button>
            ) : (
              <p id="auth-recover-counter">
                The email can be resent in {counter} seconds
              </p>
            )}
          </div>
          <button
            id="auth-recover-done-button"
            onClick={() => navigate("/dashboard")}
          >
            Done
          </button>
        </div>
      )}
    </div>
  );
}

export default Recovery;
