import React, { useState } from "react";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";

function Statistics(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const [sales, setSales] = useState(0);
  const [balance, setBalance] = useState(0);

  let navigate = useNavigate();

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser != null) {
      db.collection("users")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.data().sales) {
            setSales(doc.data().sales);
          }

          if (doc.data().sales) {
            setBalance(doc.data().balance);
          }
        })
        .catch((error) => console.log(error));
    }
  });
  return (
    <div className="account">
      <h2>Statistics</h2>
      <div className="account-info-container">
        <div className="account-info-edit-container">
          <div className="account-bar-container">
            <div>
              <p className="account-bar-heading">total sales: </p>
              <p className="account-bar-info">{sales}</p>
            </div>
          </div>
          <div className="account-bar-container">
            <div>
              <p className="account-bar-heading">commission (per sale): </p>
              <p className="account-bar-info">14,99 USD</p>
            </div>
          </div>
        </div>
        <div className="account-bar-container">
          <div>
            <p className="account-bar-heading">total balance: </p>
            <p className="account-bar-info">{balance} USD</p>
          </div>
          <button onClick={() => navigate("/dashboard/payment")}>
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
