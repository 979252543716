import React, { useState } from "react";
import firebase from "firebase";
import { motion } from "framer-motion";
import "./auth.css";
import Error from "../Error/error.js";

import firebaseApp from "../../../firebaseConfig";
import { useNavigate } from "react-router-dom";

const auth = firebase.auth(firebaseApp);
const db = firebase.firestore(firebaseApp);

function Signup(props) {
  const navigate = useNavigate();

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerRepeatPassword, setRegisterRepeatPassword] = useState("");

  const [sendMail, setSendMail] = useState(true);

  const [authError, setAuthError] = useState("");

  const register = async () => {
    setAuthError("");

    if (registerEmail == "") {
      setAuthError("Please type in your email address");
      return;
    }

    if (
      registerUsername == "" ||
      registerUsername.length < 3 ||
      registerUsername.length > 16
    ) {
      setAuthError(
        "Please enter a valid username (usernames must be between 3 and 16 characters long)"
      );
      return;
    }

    if (registerPassword == "") {
      setAuthError("Please type in a password");
      return;
    }

    if (registerRepeatPassword == "") {
      setAuthError("Please repeat your password");
      return;
    }

    if (registerPassword != registerRepeatPassword) {
      setAuthError("The passwords do not match. Please repeat your password");
      return;
    }

    if (registerPassword == registerRepeatPassword) {
      try {
        const user = await auth.createUserWithEmailAndPassword(
          registerEmail,
          registerPassword
        );
        db.collection("users").doc(user.user.uid).set({
          username: registerUsername,
          email: registerEmail,
          emailOptIn: sendMail,
          designs: 0,
          balance: 0,
          about: "",
        });
        navigate("../dashboard/designs");
      } catch (error) {
        setRegisterPassword("");
        setRegisterRepeatPassword("");
        setAuthError(error.code);
        console.log(error.code);
        switch (error.code) {
          case "auth/invalid-email":
            setAuthError("The email you entered is invalid");
            return;
          case "auth/email-already-in-use":
            setAuthError("The email you entered is already in use");
            return;
          case "auth/invalid-password":
            setAuthError(
              "The password you entered is invalid. Your password must be at least six characters long"
            );
            return;
          case "auth/weak-password":
            setAuthError(
              "The password you entered is too weak. Your password must be at least six characters long"
            );
            return;
          default:
            setAuthError(
              "Something went wrong... please check your internet connection and try again later"
            );
            return;
        }
      }
    }
  };

  return (
    <div className="auth-modal-layout">
      {authError != "" ? <Error message={authError} /> : ""}
      <h3 className="auth-modal-header">Create an account</h3>
      <div className="auth-modal-content">
        <label className="auth-modal-lable">email</label>
        <input
          className="auth-modal-input"
          type="email"
          value={registerEmail}
          onChange={(event) => setRegisterEmail(event.target.value)}
        />
        <label className="auth-modal-lable">username</label>
        <input
          className="auth-modal-input"
          type="text"
          value={registerUsername}
          onChange={(event) => setRegisterUsername(event.target.value)}
        />
        <label className="auth-modal-lable">password</label>
        <input
          className="auth-modal-input"
          type="password"
          value={registerPassword}
          onChange={(event) => setRegisterPassword(event.target.value)}
        />
        <label className="auth-modal-lable">repeat password</label>
        <input
          className="auth-modal-input"
          type="password"
          value={registerRepeatPassword}
          onChange={(event) => setRegisterRepeatPassword(event.target.value)}
        />
        <label>
          <input
            type="checkbox"
            defaultChecked={sendMail}
            onChange={() => setSendMail(!sendMail)}
          />
          {"  "}I would like to recieve email communication about updates and
          news from this project.
        </label>
        <button className="auth-modal-button" onClick={register}>
          create account
        </button>
      </div>
    </div>
  );
}

export default Signup;
