import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./error.css";

function Error(props) {
  return (
    <AnimatePresence>
      <motion.div
        className="error-container"
        initial={{ y: "-20%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: "spring",
          damping: 25,
          stiffness: 600,
        }}
      >
        <p>{props.message}</p>
      </motion.div>
    </AnimatePresence>
  );
}

export default Error;
