import React, { useState } from "react";
import Login from "./login.js";
import Signup from "./signup.js";

function Auth(props) {
  const [logingIn, setLogingIn] = useState(true);

  return (
    <div>
      {logingIn ? (
        <div className="auth-layout">
          <Login />
          <p className="auth-change-auth-mode">
            Don't have an account yet?{" "}
            <a onClick={() => setLogingIn(false)}>Create an account</a>
          </p>
        </div>
      ) : !logingIn ? (
        <div className="auth-layout">
          <Signup />
          <p className="auth-change-auth-mode">
            Already have an account?{" "}
            <a onClick={() => setLogingIn(true)}>Sign in</a>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Auth;
