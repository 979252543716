import React from "react";

import Hero from "./Hero/hero";
import About from "./About/about";
import Footer from "./Footer/footer";

import logo from "../images/Logo2.svg";

import Headline from "./Headline/headline";

function Home(props) {
  return (
    <div className="home-container">
      <img
        src={logo}
        height="160"
        width="160"
        className="home-logo"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      />
      <Hero />
      <About />
      <Footer />
    </div>
  );
}

export default Home;
