import React from "react";

import {
  MdOutlineDesignServices,
  MdAddCircleOutline,
  MdOutlineInsights,
  MdOutlinePayments,
  MdOutlineManageAccounts,
} from "react-icons/md";

export const SidebarData = [
  {
    title: "My Designs",
    icon: <MdOutlineDesignServices />,
    link: "/dashboard/designs",
  },
  {
    title: "Upload",
    icon: <MdAddCircleOutline />,
    link: "/dashboard/upload",
  },
  {
    title: "Statistics",
    icon: <MdOutlineInsights />,
    link: "/dashboard/statistics",
  },
  /*{
    title: "Payment",
    icon: <MdOutlinePayments />,
    link: "/dashboard/payment",
  },*/
  {
    title: "Account",
    icon: <MdOutlineManageAccounts />,
    link: "/dashboard/account",
  },
];
