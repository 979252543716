export const cropImage = async (imgUri, crop, callback) => {
  try {
    let resize_canvas = document.createElement("canvas");
    let orig_src = new Image();
    orig_src.src = imgUri;
    orig_src.onload = function () {
      resize_canvas.width = crop.width;
      resize_canvas.height = crop.height;
      let cnv = resize_canvas.getContext("2d");
      cnv.drawImage(
        orig_src,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );
      let newimgUri = resize_canvas.toDataURL("image/png ").toString();
      callback(newimgUri);
    };
  } catch (e) {
    console.log("Couldn't crop image due to", e);
    window.alert("Couldn't crop image due to", e);
  }
};
