import React, { useEffect, useState } from "react";
import firebase from "firebase";
import "./designs.css";
import { useNavigate } from "react-router-dom";

import { MdOutlineImageSearch } from "react-icons/md";

import griptape from "../../../../images/griptape.jpg";

function Designs(props) {
  const navigate = useNavigate();

  const db = firebase.firestore();
  const auth = firebase.auth();

  const [decks, setDecks] = useState([]);

  const [order, setOrder] = useState("title");

  useEffect(() => {
    queryDesigns();
  }, []);

  const queryDesigns = async () => {
    const data = await db
      .collection("designs")
      .where("creatorUID", "==", auth.currentUser.uid)
      .get();

    data.docs.forEach((item) => {
      setDecks((decks) => [...decks, item]);
    });
  };

  const getDate = (timestamp) => {
    const current = timestamp.toDate();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  return (
    <div>
      {decks.length >> 0 ? (
        <div className="designs">
          <div className="designs-list-header">
            <h2>My Designs</h2>
            <div className="designs-list-sort-container">
              <p>sort by:</p>
              <select value={order} onChange={(e) => setOrder(e.target.value)}>
                <option value="title">title (a-z)</option>
                <option value="date">upload date</option>
                <option value="sales">sales</option>
              </select>
            </div>
          </div>
          <div className="designs-list">
            {decks
              .sort((a, b) =>
                order === "title"
                  ? a.data().title.toLowerCase() > b.data().title.toLowerCase()
                    ? 1
                    : b.data().title.toLowerCase() >
                      a.data().title.toLowerCase()
                    ? -1
                    : 0
                  : order === "date"
                  ? a.data().uploadDate - b.data().uploadDate
                  : order === "sales"
                  ? b.data().sales - a.data().sales
                  : ""
              )

              .map((item) => {
                return (
                  <div
                    key={item.id}
                    className="designs-list-element"
                    onClick={() => navigate(item.id)}
                  >
                    <img src={item.data().imagePath} />
                    <div className="designs-list-element-title">
                      <h2>{item.data().title}</h2>
                      <p>upload date: {getDate(item.data().uploadDate)}</p>
                    </div>
                    <p className="designs-list-element-sales">
                      {item.data().sales} sold
                    </p>
                    <div className="designs-list-element-status">
                      <div
                        id={
                          item.data().status === "review"
                            ? "yellow"
                            : item.data().status === "online"
                            ? "green"
                            : "red"
                        }
                      ></div>
                      <p>{item.data().status}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <p className="designs-note">
            Note: Our service is still in an early stage of development.
            Therefore, decks are not yet available for purchase. You will be
            notified once the shop is launched. Thank's for your patience and
            understanding :)
          </p>
        </div>
      ) : (
        <div className="designs">
          <div className="designs-list-empty-container">
            <MdOutlineImageSearch className="designs-list-empty-icon" />
            <p>
              You haven't uploaded any designs yet. Click{" "}
              <a onClick={() => navigate("../dashboard/upload")}>here</a> to go
              to the upload page.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Designs;
