import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBHCVgZl8kDg7-HOW4LVH4mAObDpEWC8fA",
  authDomain: "skate-61344.firebaseapp.com",
  projectId: "skate-61344",
  storageBucket: "skate-61344.appspot.com",
  messagingSenderId: "68350975459",
  appId: "1:68350975459:web:f63a8aadeed10315ac94d6",
  measurementId: "G-RGTMTRZBCG",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
