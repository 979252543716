import React, { useEffect, useState } from "react";
import firebase from "firebase";
import firebaseApp from "../../../firebaseConfig";
import "./auth.css";

import Error from "../Error/error.js";
import { useNavigate } from "react-router-dom";

const auth = firebase.auth(firebaseApp);

function Login(props) {
  let navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [authError, setAuthError] = useState("");

  const [resettingPassword, setResettingPassword] = useState(false);

  const login = async () => {
    setAuthError("");

    if (loginEmail == "") {
      setAuthError("Please type in your email address");
      return;
    }

    if (loginPassword == "") {
      setAuthError("Please type in your password");
      return;
    }

    try {
      const user = await auth.signInWithEmailAndPassword(
        loginEmail,
        loginPassword
      );
      navigate("../dashboard/designs");
    } catch (error) {
      setLoginPassword("");
      setAuthError(error.code);

      switch (error.code) {
        case "auth/invalid-email":
          setAuthError(
            "The email address you entered is invalid. Please type in a valid email address"
          );
          return;
        case "auth/wrong-password":
          setAuthError(
            "The email address or password is not correct. Please check your credentials"
          );
          return;
        case "auth/user-not-found":
          setAuthError(
            "There is no user registered to this email address. Please check your email address or try again later"
          );
          return;
        default:
          setAuthError(
            "Something went wrong... please check your internet connection and try again later"
          );
          return;
      }
    }
  };

  return (
    <div className="auth-modal-layout">
      {authError != "" ? <Error message={authError} /> : ""}
      <h3 className="auth-modal-header">Login</h3>
      <div className="auth-modal-content">
        <label className="auth-modal-lable">email</label>
        <input
          className="auth-modal-input"
          type="email"
          value={loginEmail}
          onChange={(event) => setLoginEmail(event.target.value)}
        />
        <label className="auth-modal-lable">password</label>
        <input
          className="auth-modal-input"
          type="password"
          value={loginPassword}
          onChange={(event) => setLoginPassword(event.target.value)}
        />
        <a
          className="auth-modal-recovery"
          onClick={() => navigate("../recover-password")}
        >
          Forgot Password?
        </a>
        <button className="auth-modal-button" onClick={login}>
          Log in
        </button>
      </div>
    </div>
  );
}

export default Login;
